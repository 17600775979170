<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="pageBack">人才库</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{userName}} -- 求职意向</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div class="btnBox" style="margin-left:20px">
              <!--              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>-->
              <el-button style="margin-left:20px" class="bgc-bv" round @click="showUpdateData()">新增求职意向</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="table"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />
              <el-table-column
                  label="期望职位"
                  align="left"
                  prop="positionName"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="期望行业"
                  align="left"
                  prop="industryName"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="期望城市"
                  align="left"
                  prop="areaName"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="期望薪资"
                  align="left"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="{ row }">
                  <template v-if="row.compensationMin&&row.compensationMax">
                    {{row.compensationMin*1000}} ~ {{row.compensationMax*1000}}
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                  label="工作性质"
                  align="left"
                  prop="jobNature"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="{ row }">
                  {{ $setDictionary("HR_JOB_NATURE", row.jobNature) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="是否接受外地务工"
                  align="left"
                  prop="migrantWorkers"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="{ row }">
                  {{ row.migrantWorkers == '1'?'是':row.migrantWorkers == '0'?'否':'--' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <div slot-scope="{ row }">
                  <el-button type="text"
                             style="padding:0px 5px"
                             size="mini"
                             @click="showUpdateData(row)"
                  >编辑</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="deleteData(row.jobIntentionId)"
                  >删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <div>
<!--        <h3 style="padding: 0 0 20px 0;">沟通记录</h3>-->
        <el-form
            :model="dataForm"
            ref="dataForm"
            :rules="dataRules"
            label-width="150px"
        >
          <el-form-item label="期望职位：" prop="positionName">
            <el-input v-model="dataForm.positionName" size="small" placeholder="请输入期望职位" @focus="inputpositio"></el-input>
          </el-form-item>
          <el-form-item label="期望行业：" prop="industryName">
            <el-input v-model="dataForm.industryName" size="small" placeholder="请输入期望行业" @focus="inputpositio2"></el-input>
          </el-form-item>
          <el-form-item label="期望城市：" prop="itemRole">
            <el-cascader
                :props="{ emitPath: false,checkStrictly: true }"
                v-model="dataForm.workAreaId"
                :options="areaList"
                size="small"
                clearable
            ></el-cascader>
          </el-form-item>
          <el-row>
            <el-col :span="11">
              <el-form-item label="期望薪资：" prop="compensationMin">
                <el-input-number v-model="dataForm.compensationMin" size="small" :min="1" :max="dataForm.compensationMax||250" :controls="false" placeholder="请输入最小值" style="width: 100%;" :precision="0"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="1"><span style="width: 100%;text-align: center;line-height: 40px;">-</span></el-col>
            <el-col :span="11">
              <el-form-item label="" prop="compensationMax" label-width="0">
                <el-input-number v-model="dataForm.compensationMax" size="small" :min="dataForm.compensationMin" :max="250" :controls="false" placeholder="请输入最大值" style="width: calc( 100% - 150px);" :precision="0"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="1"><span style="width: 100%;text-align: center;line-height: 40px;">千元</span></el-col>
          </el-row>

          <el-form-item label="工作性质：" prop="jobNature">
            <el-select
                size="small"
                v-model="dataForm.jobNature"
                clearable
            >
              <el-option
                  v-for="item in jobNatureList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否接受外地务工：" prop="migrantWorkers">
            <el-select
                size="small"
                v-model="dataForm.migrantWorkers"
                clearable
            >
              <el-option key="1" label="是" value="1"></el-option>
              <el-option key="0" label="否" value="0"></el-option>
            </el-select>
          </el-form-item>


        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 期望职位 -->
    <el-dialog title="期望职位" :visible.sync="previewLoding" width="40%" top="5%" center>
      <div class="zjzw">
        <div>
          <div
              :class="previewLodingData_1_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_1"
              :key="index"
              @click="selectpreview(1,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
        <div>
          <div
              :class="previewLodingData_2_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_2"
              :key="index"
              @click="selectpreview(2,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
        <div>
          <div
              :class="previewLodingData_3_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_3"
              :key="index"
              @click="selectpreview(3,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 期望行业 -->
    <el-dialog title="期望行业" :visible.sync="previewLoding2" width="40%" top="5%" center>
      <div class="qwhy">
        <div>
          <div
              :class="previewLoding2Data_1_industryTypeId == item.industryTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLoding2Data_1"
              :key="index"
              @click="selectpreview2(1,item,item.industryTypeId)"
          >{{ item.industryTypeName }}</div>
        </div>
        <div>
          <div
              :class="previewLoding2Data_2_industryTypeId == item.industryTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLoding2Data_2"
              :key="index"
              @click="selectpreview2(2,item,item.industryTypeId)"
          >{{ item.industryTypeName }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "jobHuntingIntention",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      talentId: this.$route.query.talentId,
      userName: this.$route.query.userName,

      salaryRequireList: [], // 薪资要求List

      dialogTitle: '',
      dialogVisible: false, //新增编辑弹窗开关

      dataForm: {
        jobIntentionId: "",
        positionId: "",
        positionName: "",
        industryId: "",
        industryName: "",
        workAreaId: "",
        compensationMin: undefined,
        compensationMax: undefined,
        jobNature: "",
        migrantWorkers: "",
      },


      dataRules: {
        positionName: [{ required: true, message: "请选择期望职位", trigger: "blur" }],
      },

      areaList: [], //期望工作城市list
      jobNatureList: [], //工作性质list

      // 期望职位 - 弹框
      previewLoding: false,
      // 期望职位 - 一级类数据 - 也是所有数据
      previewLodingData_1: [],
      // 期望职位 - 二级类数据
      previewLodingData_2: [],
      // 期望职位 - 三级类数据
      previewLodingData_3: [],
      // 期望职位 - 一级类数据 - 当前id
      previewLodingData_1_positionTypeId: "",
      // 期望职位 - 二级类数据 - 当前id
      previewLodingData_2_positionTypeId: "",
      // 期望职位 - 三级类数据 - 当前id
      previewLodingData_3_positionTypeId: "",

      // 期望行业 - 弹框
      previewLoding2: false,
      // 期望职位 - 一级类数据 - 也是所有数据
      previewLoding2Data_1: [],
      // 期望职位 - 二级类数据
      previewLoding2Data_2: [],
      // 期望职位 - 一级类数据 - 当前id
      previewLoding2Data_1_industryTypeId: "",
      // 期望职位 - 二级类数据 - 当前id
      previewLoding2Data_2_industryTypeId: "",
      retrievalData:{},

      currentPage:''
    };
  },
  computed: {},
  created() {
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }

    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
    this.getPreviewLoding();
    this.getPreviewLoding2();
    this.getcity();
    this.getDropDownData();
    // this.getDropDownData()
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/personnelList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
          .then((res) => {
            this.areaList = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    // 获取 all- 下拉数据
    getDropDownData() {
     // 工作性质list
      const jobNatureList = this.$setDictionary(
          "HR_JOB_NATURE",
          "list"
      );
      for (const key in jobNatureList) {
        this.jobNatureList.push({
          value: key,
          label: jobNatureList[key],
        });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.talentId
      };
      this.doFetch({
        url: "/hr/talent/info/jobIntentionLogPage",
        params,
        pageNum
      });
    },
    // 打开新增&编辑
    showUpdateData(row){
      this.dialogVisible = true
      let str = '求职意向'
      this.$nextTick(()=>{
        this.$refs["dataForm"].resetFields();
        this.dataForm = {
          jobIntentionId: "",
          positionId: "",
          positionName: "",
          industryId: "",
          industryName: "",
          workAreaId: "",
          compensationMin: undefined,
          compensationMax: undefined,
          jobNature: "",
          migrantWorkers: '',
        }
        if(row){
          this.dialogTitle = '编辑' + str
          this.dataForm = {
            ...row
          }
          // this.dataForm.itemTime = [ row.beginTime.substring(0,10), row.endTime.substring(0,10) ]
          this.dataForm.jobIntentionId = row.jobIntentionId
        }else{
          this.dialogTitle = '新增' + str
          this.dataForm.jobIntentionId = ""
        }
      })
    },
    // 关闭新增&编辑
    handleClose() {
      this.dialogVisible = false
    },
    // 弹层保存数据
    saveData(){
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let params = {
            talentId: this.talentId,
            positionId: this.dataForm.positionId,
            positionName: this.dataForm.positionName,
          }
          if(this.dataForm.jobIntentionId != ""){
            params.jobIntentionId = this.dataForm.jobIntentionId
          }
          if(this.dataForm.industryId){
            params.industryId = this.dataForm.industryId
            params.industryName = this.dataForm.industryName
          }
          if(this.dataForm.workAreaId){
            params.workAreaId = this.dataForm.workAreaId
          }
          if(this.dataForm.compensationMin){
            params.compensationMin = this.dataForm.compensationMin
          }
          if(this.dataForm.compensationMax){
            params.compensationMax = this.dataForm.compensationMax
          }
          if(this.dataForm.jobNature){
            params.jobNature = this.dataForm.jobNature
          }
          if(this.dataForm.migrantWorkers){
            params.migrantWorkers = this.dataForm.migrantWorkers
          }

          console.log(params)
          this.$post(
              "/hr/talent/info/saveOrUpdateJobIntention",
              params
          ).then((res) => {
            if (res.status == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.dialogVisible = false
              this.getData(-1);
            }
          });
        }
      })
    },
    // 删除列表数据
    deleteData(id){
      let str = '求职意向'
      this.$confirm(
          "是否删除此条" + str,
          "提示",
          {
            confirmButtonText: "确定删除",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            let params = {
              talentId: this.talentId,
              jobIntentionId: id
            }
            console.log(params)
            this.$post(
                "/hr/talent/info/removeJobIntentionLog",
                params
            ).then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.getData(-1);
              }
            });
          })
          .catch(() => {
            return;
          });
    },
    // 点击 - 期望职位
    inputpositio(){
      this.previewLoding = true;
      // 期望职位 - 二级类数据
      this.previewLodingData_2= []
      // 期望职位 - 三级类数据
      this.previewLodingData_3= []
      // 期望职位 - 一级类数据 - 当前id
      this.previewLodingData_1_positionTypeId= ""
      // 期望职位 - 二级类数据 - 当前id
      this.previewLodingData_2_positionTypeId= ""
      // 期望职位 - 三级类数据 - 当前id
      this.previewLodingData_3_positionTypeId= ""
    },
    // 获取 - 期望职位：但只取当前第一组数据
    getPreviewLoding() {
      this.$post("/hr/talent/hr-position-type/queryAll")
          .then(res => {
            this.previewLodingData_1 = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    // 点击 - 职业分类
    selectpreview(type,item,positionTypeId){
      if(type == 1) {
        this.previewLodingData_1_positionTypeId = positionTypeId;
        this.previewLodingData_2 = item.children;
        this.previewLodingData_3 = [];
      }
      if(type == 2) {
        this.previewLodingData_2_positionTypeId = positionTypeId;
        this.previewLodingData_3 = item.children;
      }
      if(type == 3) {
        this.previewLodingData_3_positionTypeId = positionTypeId;
        this.dataForm.positionId = positionTypeId;
        this.dataForm.positionName = item.positionTypeName;
        this.previewLoding = false;
      }
    },
    // 点击 - 期望行业
    inputpositio2(){
      this.previewLoding2 = true;
      // 期望职位 - 二级类数据
      this.previewLoding2Data_2= []
      // 期望职位 - 一级类数据 - 当前id
      this.previewLoding2Data_1_industryTypeId= ""
      // 期望职位 - 二级类数据 - 当前id
      this.previewLoding2Data_2_industryTypeId= ""
    },
    // 获取 - 期望行业：但只取当前第一组数据
    getPreviewLoding2() {
      this.$post("/hr/talent/hr-industry-type/queryAll")
          .then(res => {
            this.previewLoding2Data_1 = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    // 点击 - 行业分类
    selectpreview2(type,item,industryTypeId){
      if(type == 1) {
        this.previewLoding2Data_1_industryTypeId = industryTypeId;
        this.previewLoding2Data_2 = item.children;
      }
      if(type == 2) {
        this.previewLoding2Data_2_industryTypeId = industryTypeId;
        this.dataForm.industryId = industryTypeId;
        this.dataForm.industryName = item.industryTypeName;
        this.previewLoding2 = false;
      }
    },

  },
  beforeRouteLeave: resetKeepAlive,
  watch: {}
};
</script>
<style lang="less" scoped>
.el-rate__icon {
  margin-right: 0;
}
.ts_1 {
  border-left: 4px solid #6383f9;
  padding-left: 4px !important;
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #b7c6fd);
}
.el-form {
  .el-form-item {
    margin-bottom: 18px;
  }
}
.zjzw {
  height: 450px;
  display: flex;
  justify-content: space-between;
  > div {
    overflow-y: auto;
    overflow-x: hidden;
    width: 33.33%;
    margin-right: 4%;
    > div {
      line-height: 26px;
      padding-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
.qwhy{
  height: 450px;
  display: flex;
  justify-content: space-between;
  > div {
    overflow-y: auto;
    overflow-x: hidden;
    width: 50%;
    margin-right: 4%;
    > div {
      line-height: 26px;
      padding-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
</style>
